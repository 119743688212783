// Libraries
import React, { useState, useEffect } from 'react';

// Components
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import Loader from '../../components/loader';
import Generator from '../../components/generator';
import Hero from '../../components/hero';

// Styles
import * as styles from '../../components/name-generator.module.css';

// Assets
import Araby from '../../../public/araby.webp';
import Bretonnia from '../../../public/bretonnia.webp';
import ChaosDwarfs from '../../../public/chaos-dwarfs.webp';
import ChaosWarriors from '../../../public/chaos-warriors.webp';
import Daemons from '../../../public/daemons.webp';
import DarkElves from '../../../public/dark-elves.webp';
import Dwarves from '../../../public/dwarves.webp';
import Estalia from '../../../public/estalia.webp';
import Fimir from '../../../public/fimir.webp';
import Halflings from '../../../public/halflings.webp';
import HighElves from '../../../public/high-elves.webp';
import Kislev from '../../../public/kislev.webp';
import TheEmpire from '../../../public/the-empire.webp';
import Tilea from '../../../public/tilea.webp';
import heroImage from '../../../public/hero.webp';
import map from '../../../public/map.webp';
import {
  NAME_GENERATOR_BASE_URL,
  NAME_GENERATOR_ENDPOINTS,
} from '../../utils/constants';

const imageMap = {
  Araby,
  Bretonnia,
  ChaosDwarfs,
  ChaosWarriors,
  Daemons,
  DarkElves,
  Dwarves,
  Estalia,
  Fimir,
  Halflings,
  HighElves,
  Kislev,
  TheEmpire,
  Tilea,
};

const NameGenerator = (props) => {
  const [races, setRaces] = useState([]);
  const [selectedRace, setSelectedRace] = useState(undefined);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    fetch(`${NAME_GENERATOR_BASE_URL}${NAME_GENERATOR_ENDPOINTS.options}`)
      .then((resp) => resp.json())
      .then((resp) => setRaces(resp.races))
      .catch(() => setHasError(true));
  }, []);

  const handleGeneratorClick = (race) => {
    setSelectedRace(race);
  };

  const renderRaces = () => {
    return races.map((race) => {
      return (
        <li
          tabIndex={0}
          key={race.name}
          className={styles.generator}
          onClick={() => handleGeneratorClick(race)}
        >
          <img
            className={styles.generatorImage}
            src={imageMap[race.name.split(' ').join('')] || heroImage}
            alt={race.name}
          />
          <h2>{race.name}</h2>
        </li>
      );
    });
  };

  return (
    <Layout location={props.location}>
      <Seo title="Name Generator" />
      <Hero title="Name Generator" content="" image={map} useImageTag />
      <Generator
        race={selectedRace}
        isOpen={!!selectedRace}
        closeModal={() => setSelectedRace(undefined)}
      />
      <Loader
        isLoading={races.length === 0}
        text={
          hasError
            ? 'There was an unexpected error. Please try again later.'
            : 'This may take a bit...'
        }
        hasError={hasError}
      >
        <ul className={styles.generatorsContainer}>{renderRaces()}</ul>
      </Loader>
    </Layout>
  );
};

export default NameGenerator;
