// Libraries
import React, { useState, useEffect, useRef } from 'react';
import { capitalize } from 'lodash';

// Components
import Loader from './loader';

// Styles
import * as styles from './name-generator.module.css';

// Constants
import {
  KEYS,
  NAME_GENERATOR_BASE_URL,
  NAME_GENERATOR_ENDPOINTS,
} from '../utils/constants';

const Generator = ({ race, isOpen, closeModal }) => {
  const modal = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(1);
  const [gender, setGender] = useState('male');
  const [chaosGod, setChaosGod] = useState('khorne');
  const [names, setNames] = useState([]);

  useEffect(() => {
    modal?.current?.scrollIntoView(false);

    return () => {
      setAmount(0);
      setNames([]);
      setGender('male');
      setChaosGod('khorne');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const createGeneratorRoute = (queryParams) =>
    `${NAME_GENERATOR_BASE_URL}${NAME_GENERATOR_ENDPOINTS.generate}?${queryParams}`;

  const handleGeneratorSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch(
      createGeneratorRoute(
        `raceName=${race.name}&amount=${amount}&gender=${gender}&chaosGod=${chaosGod}`
      )
    )
      .then((resp) => resp.json())
      .then((resp) => {
        setNames(resp[race.name]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.overlay}>
      <div ref={modal} className={styles.generatorModal}>
        <div className={styles.title}>
          <h3>{race.name}</h3>
          <div
            className={styles.close}
            onClick={closeModal}
            onKeyDown={(e) => e.key === KEYS.escape && closeModal()}
            role="button"
            tabIndex={0}
          >
            X
          </div>
        </div>
        <form onSubmit={handleGeneratorSubmit}>
          {race.options?.genders && (
            <>
              <label htmlFor="gender">Gender:</label>
              <select name="gender" onChange={(e) => setGender(e.target.value)}>
                {race.options.genders.map((gender) => (
                  <option value={gender} key={gender}>
                    {capitalize(gender)}
                  </option>
                ))}
              </select>
            </>
          )}
          {race.options?.chaosGods && (
            <>
              <label htmlFor="chaosGod">Chaos God:</label>
              <select
                name="chaosGod"
                onChange={(e) => setChaosGod(e.target.value)}
              >
                {race.options.chaosGods.map((cg) => (
                  <option value={cg} key={cg}>
                    {capitalize(cg)}
                  </option>
                ))}
              </select>
            </>
          )}
          <label htmlFor="amount">Number of names:</label>
          <input
            name="amount"
            type="number"
            max={200}
            min={1}
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
          />
          <button type="submit">Submit</button>
        </form>
        <hr />
        <Loader isLoading={isLoading}>
          {Boolean(names.length) && (
            <table className={styles.resultsTable}>
              <tr className={styles.resultsTableHeaderRow}>
                <th>Position</th>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
              {names.map(
                ({ name, firstName, lastName, clan, fimirName }, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{name || firstName || fimirName}</td>
                      <td>{lastName || clan}</td>
                    </tr>
                  );
                }
              )}
            </table>
          )}
        </Loader>
      </div>
    </div>
  );
};

export default Generator;
